
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

interface ProfileDetails {
  avatar: string;
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  password_confirmation: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    let errorsRef = ref(null);
    let file;
    const router = useRouter();
    const profileDetailsValidator = Yup.object().shape({
      first_name: Yup.string().required().label("First name"),
      last_name: Yup.string().required().label("Last name"),
      username: Yup.string().required().label("Username"),
      password: Yup.string().min(8).required().label("Password"),
      password_confirmation: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      avatar: "media/avatars/blank.png",
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      password_confirmation: "",
    });

    const saveChanges1 = () => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButton1.value || count > 0) {
        return;
      }
      submitButton1.value.setAttribute("disabled", "disabled");

      submitButton1.value.setAttribute("data-kt-indicator", "on");
      let formData = new FormData();
      let json = JSON.parse(JSON.stringify(profileDetails.value));
      formData.append("first_name", json?.first_name);
      formData.append("last_name", json?.last_name);
      formData.append("username", json?.username);
      formData.append("password", json?.password);
      formData.append("password_confirmation", json?.password_confirmation);
      file ? formData.append("avatar", file) : null;
      apiService
        .post(`/`, formData)
        .then(() => {
          success();
          file = null;
        })
        .catch(function (error) {
          if (submitButton1.value) {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            submitButton1.value?.removeAttribute("disabled");
          }
          if (submitButton1.value) {
            submitButton1.value?.removeAttribute("disabled");
            submitButton1.value?.removeAttribute("data-kt-indicator");
          }
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
          if (error.response && error.response.status == 422) {
            getError(error.response?.data.errors);
            file = null;
          }
        });
    };

    const removeImage = () => {
      profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Create", ["Admin"]);
    });

    function getError(error) {
      errorsRef.value = error;
      if (submitButton1.value) {
        submitButton1.value?.removeAttribute("data-kt-indicator");
        submitButton1.value?.removeAttribute("disabled");
      }
    }

    function success() {
      if (submitButton1.value) {
        submitButton1.value?.removeAttribute("data-kt-indicator");
        submitButton1.value?.removeAttribute("disabled");
        let button = document.getElementById("resetButton");
        button?.click();
        removeImage();
      }
      Swal.fire({
        text: `The Admin has been successfully created!`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }

    const changeAvatar = (event) => {
      hideError("avatar");
      profileDetails.value.avatar = URL.createObjectURL(event.target.files[0]);
      file = event.target.files[0];
    };
    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    return {
      submitButton1,
      saveChanges1,
      profileDetails,
      removeImage,
      profileDetailsValidator,
      changeAvatar,
      errorsRef,
      hideError,
    };
  },
});
